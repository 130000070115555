.new-table {
  &__navigation {
    border-bottom: 1px solid #D0D3D9;

    &-inner {
      padding: 0 40px;
    }

    &-left {
      display: flex;
      align-items: center;

      .back {
        border: 1px solid #D0D3D9;
        border-radius: 2px;
        width: 33px;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        background-color: #fff;
        cursor: pointer;
      }

      p {
        color: #142341;
        font-weight: 700;
      }
    }
  }

  &__grid {
    position: relative;
    width: 100%;
    height: calc(100vh - 134px);
    display: flex;
    justify-content: space-between;
    text-align: center;
    background-color: #fff;

    @media (max-width: 1350px) {
      justify-content: flex-end;
    }

    &-input {
      display: flex;
      flex-direction: column;
      margin: 40px 0;

      label {
        text-align: left;
      }

      &-row {
        display: flex;
        justify-content: space-between;

        .visual-style-card {
          border: 2px solid #D0D3D9;
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          font-size: 16px;
          font-weight: 700;
          text-align: center;
          padding: 10px;

          img {
            width: 100%;
            margin: 10px auto;
          }
        }
      }
    }
  }

  &__column {
    position: relative;
    height: 100%;
    overflow: auto;

    &:nth-child(1) {
      @media (max-width: 1350px) {
        display: none;
      }
    }

    &:nth-child(1),
    &:nth-child(3) {
      width: 100%;
      max-width: 350px;
      min-width: 350px;

      @media (max-width: $viewport-mobile--max) {
        display: none;
      }
    }

    &:nth-child(2) {
      width: 100%;
      padding: 0 40px;
    }

    &--help {
      background-color: $gray--lightest;
      padding: 20px 40px;
      text-align: left;

      .intro-text {
        color: $primary;

        i {
          margin-right: 5px;
        }
      }
    }
  }

  &__column-inner {
    max-width: 660px;
    margin: 0 auto;
  }
}
