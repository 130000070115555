@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  width: 1.5em;
  height: 1.25em;
  text-align: center;
  color: $primary;
  cursor: help;

  &--right {
    .tooltip {
      &__tip {
        left: calc(100% + 5px);
        top: 50%;
        transform: translateY(-50%);

        &:before {
          left: -7px;
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
        }
      }
    }
  }

  &:hover {
    .tooltip {
      &__tip {
        display: block;
      }
    }
  }

  &__tip {
    display: none;
    position: absolute;
    text-align: left;
    font-weight: normal;
    width: 250px;
    color: $text;
    border-radius: 4px;
    box-shadow: $shadow--level-2;
    padding: 15px;
    z-index: 99;
    animation: fade-in .2s;

    &:before {
      content: "";
      position: absolute;
      display: block;
      z-index: -1;
      width: 15px;
      height: 15px;
      box-shadow: $shadow--level-2;
      background-color: #fff;
    }
  }

  &__tip-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 4px;
    z-index: -1;
  }
}
