.table-sidebar {
  position: relative;
  background-color: $gray--lightest;
  width: 350px;
  min-width: 350px;
  height: calc(100vh - 66px);

  @media (max-width: $viewport-laptop--max) {
    width: 300px;
    min-width: 300px;
  }

  @media (max-width: $viewport-tablet--max) {
    display: none;
  }

  &__inner {
    position: relative;
    height: calc(100% - 54px);
    padding: 40px 40px 54px 40px;
    overflow: auto;

    @media (max-width: $viewport-laptop--max) {
      padding: 40px 25px 54px 25px;
    }
  }

  &__inner-spacer {
    position: relative;
    width: 100%;
    height: 20px;
  }

  &__header {
    display: flex;
    border-bottom: 1px solid $gray--light;
    align-items: center;
    justify-content: space-between;
  }

  &__new-table {
    text-decoration: none;
    color: $gray--darker;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color .2s;

    &:hover {
      color: $links;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    text-decoration: none;
    color: $headlines;
    transition: color .2s;

    &:hover {
      color: $links;
    }
  }

  &__item-title {
    word-break: break-word;
  }

  &__item-count {
    width: 30px;
    height: 30px;
    background-color: $gray--lighter;
    border-radius: 2px;
    color: $gray;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
  }

  &__bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 16px 22px;
    border-top: solid 1px $gray--light;
    background-color: $gray--lightest;
  }

  &__help-link {
    display: inline-block;
    text-decoration: none;
    color: $gray--dark;
    transition: color .2s;

    i {
      margin-right: 5px;
    }

    &:hover {
      color: $accent-3;
    }
  }
}
