.resources {
  // height: calc(100vh - 131px);

  &__viewport {
    display: flex;
    height: calc(100vh - 126px);
    overflow: auto;
    justify-content: center;

    @media (max-width: $viewport-mobile--max) {
      flex-direction: column;
      height: auto;
    }
  }

  &__grid {
    position: relative;
    width: 100%;
    padding: 20px;
    overflow: auto;
    height: 100%;
    background-color: #fff;

    > div {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      @media (max-width: $viewport-mobile--max) {
        flex-direction: column;
      }

      > div {
        width: 33.333%;

        @media (max-width: 1300px) {
          width: 50%;
        }

        @media (max-width: $viewport-mobile--max) {
          width: 100%;
        }
      }
    }
  }
}
