input[type='text'],
input[type='email'],
input[type='phone'],
input[type='tel'],
input[type='number'],
input[type='file'],
input[type='password'],
textarea,
select {
  border: 1px solid $gray--light;
  border-radius: 2px;
  padding: 10px;
  margin: 10px 0;
  font-size: 18px;
  transition: border-color .1s, box-shadow .1s, background-color .1s;


  &.error, 
  .error &,
  &:invalid {
    border-color: #f00;
    background-color: #fff2f2;
  }

  &:placeholder-shown {
    background-color: $inputs;
    border-color: $gray--light;
  }

  &:focus {
    border-color: $accent-3;
    box-shadow: 0px 0px 6px rgba(0, 157, 201, 0.3);
  }
}

input[type='submit'] {
  display: inline-block;
  width: auto;
  background-color: $primary;
  border-radius: 2px;
  border: none;
  color: #fff;
  // padding: 10px;
  // font-size: 20px;
  // width: 40%;
  margin-top: 20px;
  cursor: pointer;
}

button {
  cursor: pointer;
}
