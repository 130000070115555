.existing-tables {
  position: relative;

  &__grid {
    display: flex;
    width: 100%;

    .table-card {
      width: 33%;
      cursor: pointer;
    }
  }

  &__interact {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__modal {
    position: relative;
    z-index: 50;
    background-color: #fff;
    width: 800px;
    box-shadow: $shadow--level-4;
    // top: 30px;
    margin: 0 auto;
    max-width: 100%;

    &-inner {
      padding: 10px 30px 30px 30px;
    }

    &-main {
      display: flex;
    }

    &-column {
      width: 50%;

      &-items {
        .column-name {
          display: flex;
          margin: 10px 0;
          outline: none;
        }
      }
    }
  }

  &__viewport {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 50px;
    width: 100%;
    height: 100vh;
    z-index: 50;
    overflow: auto;

    @media (max-width: $viewport-mobile--max) {
      padding: 15px;
   
  } }
}