.App {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  max-width: 1000px;
  margin: 40px auto;
}

.loggedin-area {
  width: 100%;
}

/* input[type="submit"] {
  background-color: #f2f2f2;
  border: 2px solid #000;
  color: #000;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
} */

.login-button-holder,
.signup-input-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.login-button-holder input,
.signup-input-holder input {
  width: 50%;
  padding: 10px;
  margin: 10px 0;
}

.auth-portal-interactions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-portal-button-holder {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 30px;
}

#login,
#signup,
.auth-portal-button-holder button {
  width: 25%;
  background-color: #fff;
  outline: none;
  border: 1px solid #000;
  padding: 10px;
  cursor: pointer;
  background-color: #f2f4f7;
  font-weight: bold;
}

.auth-portal-interactions input {
  width: 30%;
  font-size: 20px;
  margin: 10px;
}

.auth-portal-interactions .input-grouping {
  width: 100%;
}

.auth-portal-interactions .card-holder {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}

.db_card {
  background-color: #f2f4f7;
  width: 45%;
  margin: 20px;
  border: 1px solid #000;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.23), 0 10px 30px rgba(0, 0, 0, 0.19);
  cursor: pointer;
  transition: 0.2s all;
}

.db_card:hover {
  opacity: 0.9;
  box-shadow: none;
}

.db_card:active {
  border: 1px solid #f2f2f2;
}

.db_card li {
  list-style-type: none;
}

/* .logout {
  position: absolute;
  top: 30px;
  right: 80px;
} */

.bolded {
  font-weight: bold;
}

.row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.intro-row {
  display: flex;
  width: 20%;
}

.row-data {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #f2f2f2;
  border: 1px solid #000000;
  margin: 10px;
  padding: 20px;
  cursor: pointer;
}

.row-data svg:hover {
  cursor: pointer;
  z-index: 50;
}

.row-data-cell {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.modal {
  position: absolute;
  background-color: #f2f2f2;
  z-index: 50;
  width: 45%;
  padding: 30px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.23), 0 10px 30px rgba(0, 0, 0, 0.19);
  max-height: 500px;
  overflow: scroll;
}

.close-modal {
  cursor: pointer;
}

.modal-match {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.modal-match .name {
  width: 60%;
  text-align: left;
}

.modal-match .number {
  width: 40%;
  text-align: left;
}

.create-table {
  width: 100%;
}

.cards {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cards textarea {
  width: 100%;
  border: none;
  padding: 20px;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

.cards input[type="text"] {
  width: 100%;
  border: none;
  padding: 20px;
}

.cards input[type="file"], input[type="submit"]  {
  width: 100%;
}

.display-area {
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}

.display-area h3 {
  margin-top: 0px;
}

.table-option {
  border: 1px solid #000000;
  padding: 20px;
  width: 30%;
  margin: 20px auto;
  cursor: pointer;
  position: relative;
}

.row-card a {
  text-decoration: none;
}

.row-card {
  background-color: #fff;
  width: 100%;
}

.create-table-inputs {
  margin-bottom: 30px;
}

.card-image-holder {
  width: 100%;
}

.card-image-holder img {
  width: 100%;
}

.addRow {
  margin-top: 40px;
  background-color: #fff;
  border: 1px solid #000000;
  padding: 20px;
  cursor: pointer;
}

.remove-table {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  z-index: 50;
}

.radio-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.radio-group label input {
  width: 10%;
}

.radio-group-item {
  text-align: left;
  width: 50%;
}

.card-inner {
  cursor: pointer;
}

/* .card {
  width: 33%;
  position: relative;
  display: flex;
  margin-bottom: 20px;
} */

/* .card svg {
  position: absolute;
  right: 50%;
  top: -5px;
} */

.click-and-drag > div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.update-reorder {
  margin-bottom: 40px;
}

.create-resource-card {
  margin-bottom: 100px;
  padding: 30px 0 50px 0;
  background-color: #f2f2f2;
}

.add-filters button {
  margin: 30px auto;
}

.column-submit button {
  margin: 30px auto;
}

.filter {
  margin: 10px auto;
}