.resources-modal {
  position: relative;
  z-index: 50;
  background-color: #fff;
  width: 800px;
  box-shadow: $shadow--level-4;
  // top: 30px;
  margin: 0 auto;
  max-width: 100%;

  &__viewport {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 50px;
    width: 100%;
    height: 100vh;
    z-index: 50;
    overflow: auto;

    @media (max-width: $viewport-mobile--max) {
      padding: 15px;
    }
  }

  &__spacer {
    height: 50px;
  }


  @media (max-width: $viewport-mobile--max) {
    width: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: $gray--lightest;
    border-bottom: 1px solid $gray--lighter;

    @media (max-width: $viewport-mobile--max) {
      display: block;

      > div {
        max-width: none;
        text-align: center;

        &:first-child {
          margin-bottom: 10px;
        }
      }
    }

    &-name {
      color: $gray--darker;
      font-size: 16px;
      font-weight: 700;
      max-width: 50%;
    }

    &-buttons {
      max-width: 50%;

      button {
        padding: 8px 20px;
        font-size: 16px;

        &:not(:first-child) {
          margin-left: 10px;
        }
      }
    }
  }


  &__inner {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }

  &__form {
    width: 48%;

    @media (max-width: $viewport-mobile--max) {
      width: 100%;
    }

    &-input {
      &-group {
        display: flex;
        flex-direction: column;

        label {
          color: $gray--darker;
          font-size: 16px;
          font-weight: 700;
        }

        input, textarea {
          margin-top: 20px;
          font-family: $font-primary;
          background-color: #F2F4FF;
        }
      }
    }
  }

  &__display {
    width: 48%;

    @media (max-width: $viewport-mobile--max) {
      display: none;
    }

    &-title {
      padding: 0px 20px;
      color: $gray--dark;
      font-size: 14px;
    }

    .resource-card {
      &__inner {
        &:hover {
          box-shadow: none;
          cursor: auto;
        }
      }

      &__main {
        padding-bottom: 20px;
      }
    }
  }
}

.backdrop {
  width: 100%;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  opacity: .5;
}