.filter {
  margin: 0 auto;
  width: 100%;
  max-width: 660px;

  &__input {
    width: 100%;
    input {
      width: 100%;

      &:placeholder-shown {
        background-color: #F2F4FF;
      }
    }

    &-grid {
      border: 2px solid #D0D3D9;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 10px 20px;
      margin: 10px 0;
    }

    &-group {
      .option-inputs {
        position: relative;

        .add, .minus {
          position: absolute;
          top: 16px;
          right: 0;
          color: #fff;
          z-index: 30;
          cursor: pointer;
        }

        .minus {
          right: 40px;
        }
      }
    }
  }

  &__enable {
    margin: 20px 0;
  }

  &__enable-container {
    position: relative;

    input {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      cursor: pointer;
      margin: 0;
      pointer-events: none;
    }

    label {
      display: block;
      width: 100%;
      cursor: pointer;
      border: 1px solid $gray--light;
      background-color: $inputs;
      z-index: 1;
      text-align: left;
      padding: 10px 10px 10px 30px;
      border-radius: 2px;

      &:hover {
        border-color: $accent-3;
        box-shadow: 0px 0px 6px rgba(0, 157, 201, 0.3);
      }
    }

    input:checked + label {
      background-color: #fff;
      border-color: $accent-3;
    }
  }

  .add, .minus {
    width: 30px;
    height: 30px;
    background-color: $accent-3;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .minus {
    background-color: $gray--dark;
  }

  .add-option {
    width: 100%;
    border: 2px dashed #D0D3D9;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: #fff;
    padding: 15px 0;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: $accent-3;

              
    svg {
      color: #fff;
    }
  }
}