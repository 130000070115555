.header {
  display: block;
  // height: 30px;
  padding: 5px 40px;
  border-bottom: solid 1px $gray--lighter;

  &--light {
    background-color: #fff;

    .header {
      &__logo--on-dark {
        display: none;
      }
    }
  }

  &--dark {
    background-color: $gray--darker;

    .header {
      &__logo--on-light {
        display: none;
      }
    }
  }
  
  h2, p {
    margin: 0;
  }

  &__inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (max-width: $viewport-mobile--max) {
      flex-direction: column;
    }
  }

  &__center {
    h2 {
      font-weight: 400;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__user-info {
    margin-right: 30px;
  }

  &__logo {
    position: relative;
    display: block;
    height: 30px;

    svg {
      position: relative;
      display: block;
      max-height: 100%;
      width: auto;
    }
  }

  &__user-menu {
    position: relative;

    &-trigger {
      padding: 15px 0;
      border: none;
      outline: none;
      font: inherit;
      color: inherit;
      background: none;

      border: none;
      background-color: #fff;
      outline: none;
      cursor: pointer;
      font-size: 18px;
      font-weight: 500;

      i {
        font-size: 12px;
        transform: translateY(-1px);
      }
    }

    &-list {
      position: absolute;
      top: 100%;
      right: 0;
      min-width: 156px;
      background-color: #fff;
      border-radius: 2px;
      overflow: hidden;
      box-shadow: $shadow--level-2;
      z-index: 50;
    }

    &-item {
      width: 100%;

      button {
        font-size: 16px;
        padding: 15px;
        transition: color .2s;

        &:hover {
          color: $accent-3;
        }
      }

      .logout-button {
        background-color: #fff;
        border: none;
        cursor: pointer;
      }
    }
  }
}
