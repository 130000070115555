// Colors
$primary: #ED0E6A;
$accent-1: #00DA9C;
$accent-2: #8A2BE2;
$accent-3: #0F6AEC;

// Neutrals
$gray--darker: #142341;
$gray--dark: #434F67;
$gray: #727B8D;
$gray--light: #A1A7B3;
$gray--lighter: #D0D3D9;
$gray--lightest: #E8E9EC;

// Interface
$headlines: $gray--darker;
$text: $gray--darker;
$links: $accent-3;
$errors: #f00;
$inputs: #F2F4FF;

// Shadows
$shadow--level-1: 0px 0px 3px rgba(0, 0, 0, 0.1), 0px 3px 3px rgba(0, 0, 0, 0.14);
$shadow--level-2: 0px 0px 6px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.13);
$shadow--level-3: 0px 7px 20px rgba(0, 0, 0, 0.09), 0px 5px 6px rgba(0, 0, 0, 0.13);
$shadow--level-4: 0px 14px 28px rgba(0, 0, 0, 0.15), 0px 3px 10px rgba(0, 0, 0, 0.12);

// Viewport widths
$viewport-mobile--max: 767px;
$viewport-tablet--min: 768px;
$viewport-tablet--max: 991px;
$viewport-laptop--min: 992px;
$viewport-laptop--max: 1439px;
$viewport-hd--min: 1440px;

//fonts
$font-primary: frank-new, sans-serif;