* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

body {
  min-height: 100vh;
  font-family: $font-primary;
  background-color: #142341;
}
