.toolbar {
  border-bottom: 1px solid $gray--lighter;
  background-color: #fff;

  &__inner {
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: $viewport-mobile--max) {
      flex-direction: column;
    }
  }

  &__left,
  &__right,
  &__center {
    width: 33.333%;

    @media (max-width: $viewport-mobile--max) {
      width: 100%;
      justify-content: center;
    }
  }

  &__left {
    display: flex;
    align-items: center;

    .back {
      border: 1px solid $gray--lighter;
      border-radius: 2px;
      width: 33px;
      height: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      background-color: #fff;
      cursor: pointer;
      color: $accent-3;
      text-decoration: none;
      transition: background-color .2s;

      &:hover {
        background-color: $gray--lightest;
      }
    }

    p {
      color: $headlines;
      font-weight: 700;
    }
  }

  &__center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;

    @media (max-width: $viewport-mobile--max) {
      justify-content: center;
    }

    button {
      padding: 8px 20px;
    }
  }

  &__steps {
    display: flex;
    color: $gray;
    align-items: center;

    &:not(:last-child) {
      margin-right: 25px;
    }

    .circle {
      min-width: 33px;
      width: 33px;
      height: 33px;
      border-radius: 50%;
      border: 1px solid $gray;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }

  }

  .completed {
    color: $primary;
    
    .circle {
      background-color: $primary;
      color: #fff;
      border: 1px solid $primary;
    }
  }

  &__modal {
    background-color: #fff;
    box-shadow: $shadow--level-4;
    z-index: 50;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &-inner {
      padding: 40px;
    }

    &-buttons {
      display: flex;

      button {
        margin-right: 20px;
      }
    }
  }
}
