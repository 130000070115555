.dashboard {
  height: 100%;

  &__login {
    max-width: 600px;
    margin: 80px auto;
    box-shadow: 0px 14px 28px rgba(0, 0, 0, 0.15), 0px 3px 10px rgba(0, 0, 0, 0.12);
    padding: 60px;
    border-radius: 4px;
    text-align: left;
    background-color: #fff;

    .helper-text {
      margin: 5px 0 10px 0;
      color: $primary;
    }

    .response {
      margin-top: 10px;
    }
  }

  .form__login {
    // display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;

    label,
    input:not([type=submit]) {
      display: block;
      width: 100%;
    }
  }

  &__message {
    margin: 50px 0 30px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .toggle-login {
      background-color: $accent-3;
      border-radius: 2px;
      color: #fff;
      border: none;
      font-weight: 700;
      font-size: 16px;
      padding: 10px;
      margin-left: 10px;
    }
  }

  &__intro {
    max-width: 560px;
  }

  &__table {
    &-inner {
      display: flex;
    }

    &-grid {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: calc(100% + 30px);
      margin-left: -15px;
      margin-right: -15px;
      // padding-bottom: 100px;
    }

    &-display {
      width: 100%;
      padding: 5px 40px;
      overflow: auto;
      background-color: #fff;

      @media (min-width: $viewport-tablet--min) {
        height: calc(100vh - 66px);
      }

      @media (max-width: $viewport-mobile--max) {
        padding: 5px 15px;
      }
    }
  }

  &__authorize {
    padding: 20px 40px;
    height: 100vh;
    background-color: #fff;
  }

  .btn--primary {
    background: $primary;
    border-radius: 2px;
    outline: none;
    border: none;
    padding: 15px 25px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px;
  }

  .hubspot-connect {
    display: flex;
    align-items: center;
    margin: 50px 0;

    img {
      margin-right: 10px;
    }
  }
}
