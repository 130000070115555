.resources-sidebar {
  width: 470px;
  min-width: 470px;
  background-color: $gray--lightest;
  height: 100%;
  padding: 20px 30px;
  overflow: auto;
  height: 100%;

  @media (max-width: $viewport-laptop--max) {
    width: 400px;
    min-width: 400px;
  }

  @media (max-width: $viewport-tablet--max) {
    width: 300px;
    min-width: 300px;
    padding: 20px 15px;
  }

  @media (max-width: $viewport-mobile--max) {
    height: auto;
    width: 100%;
    min-width: 0;
  }

  &__inner {

  }

  &__inner-spacer {
    height: 50px;
  }

  &__input {
    background-color: #fff;
    border: 2px solid $gray--lighter;
    border-radius: 4px;
    padding: 20px;

    &-group {
      display: flex;
      flex-direction: column;
      padding-bottom: 12px;

      label {
        color: $gray--darker;
        font-weight: bold;
      }

      input,textarea,select {
        background-color: #F2F4FF;
        border-color: #A1A7B3;
        font-family: $font-primary;
      }
    }

    .add {
      width: 100%;

      .fa-plus {
        margin-right: 10px;
      }
    }
  }

  &__add-resources {
    h2 {
      margin-top: 0px;
    }
  }

  &__input-info-text {
    color: $gray--dark;
    font-size: 14px;
  }
}
