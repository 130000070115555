@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(359deg);
  }
}

.cta-btn {
  // Clear default button styles
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none;

  // .cta-btn styles
  text-decoration: none;
  color: #fff;
  background-color: $primary;
  border: 2px solid $primary;
  border-radius: 4px;
  font-size: 20px;
  font-weight: bold;
  padding: 13px 28px;
  cursor: pointer;
  transition: color .2s, background-color .2s, border-color .2s;

  &:hover {
    background-color: #D50D5F;
  }

  &:active {
    background-color: #F13E88;
    border-color: #D50D5F;
  }

  a {
    text-decoration: none;
    color: #fff;
  }

  &--stroke {
    background-color: transparent;
    padding: 13px 28px;
    font-size: 20px;
    color: $gray--darker;
    border-radius: 4px;
    border: 2px solid $gray--light;
  }

  // States
  &.inactive,
  &--inactive {
    background-color: $gray--lighter;
    border-color: $gray--lighter;
    pointer-events: none;
  }

  // Sizes
  &--small {
    font-size: 16px;
    padding: 6px 10px;
  }

  &--medium {
    font-size: 18px;
    padding: 10px 20px;
  }

  &--large {
    font-size: 20px;
    padding: 13px 28px;
  }

  // Specific buttons
  &--publish {
    i {
      display: none;
      margin-right: 5px;
      margin-left: -5px;
    }

    &.loading {
      i {
        display: inline-block;
        animation: spin 1s linear infinite;
      }
    }
  }

  &--delete {
    background-color: $gray--lighter;
    border-color: $gray--lighter;
    margin-right: 20px;

    &:hover {
      background-color: $primary;
      border-color: $primary;
      background-color: #f00;
      border-color: #f00;
    }
  }
}
