.table-card {
  padding: 15px;
  width: 20%;

  @media (max-width: $viewport-laptop--max) {
    width: 25%;
  }

  @media (max-width: $viewport-tablet--max) {
    width: 33.333%;
  }

  @media (max-width: $viewport-mobile--max) {
    width: 100%;
  }

  &--new-table {
    width: 40%;

    @media (max-width: $viewport-laptop--max) {
      width: 50%;
    }

    @media (max-width: $viewport-tablet--max) {
      width: 66.666%;
    }

    @media (max-width: $viewport-mobile--max) {
      width: 100%;
    }

    .table-card {
      &__inner {
        border-style: dashed;

        &:hover {
          box-shadow: none;
          border-color: $accent-3;
        }
      }

      &__main {
        height: 100%;
      }
    }
  }

  &__new-table {
    background-color: $accent-3;
    color: #fff;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    margin-bottom: 15px;
  }

  &__new-table-title {
    color: $accent-3;
    font-size: 20px;
    font-weight: bold;
  }

  &__inner {
    display: block;
    text-decoration: none;
    border: solid 2px $gray--lighter;
    border-radius: 4px;
    transition: box-shadow .2s, border-color .2s;
    padding: 15px;
    height: 100%;

    &:hover {
      box-shadow: $shadow--level-3;

      .table-card {
        &__edit {
          background-color: $accent-3;
          color: #fff;
        }
      }
    }

    &:active {
      border-color: $accent-3;
    }
  }

  &__main {
    min-height: 128px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    span {
      display: block;
    }
  }

  &__name {
    color: $headlines;
    font-size: 16px;
    margin-bottom: 4px;
    word-break: break-word;
  }

  &__id {
    color: $gray;
    font-size: 12px;
  }

  &__actions {
    // padding: 0 15px 15px 15px;
    display: flex;
    justify-content: space-between;
  }

  &__edit,
  &__count {
    background-color: $gray--lighter;
    color: $gray;
    width: 33px;
    height: 33px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color .2s, color .2s;
  }
}
