.resource-card {
  position: relative;
  padding: 20px;

  @media (max-width: $viewport-laptop--max) {
    padding: 10px;
  }

  &.dragged {
    z-index: 999;
    cursor: grabbing !important;
  }

  &__inner {
    position: relative;
    height: 100%;
    border: 2px solid $gray--lighter;
    border-radius: 4px;
    transition: box-shadow .2s, border-color .2s;
    background-color: #fff;
    cursor: grab;

    span {
      display: block;
    }

    &:hover {
      box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.09), 0px 5px 6px rgba(0, 0, 0, 0.13);

      .resource-card {
        &__edit {
          background-color: $accent-3;
          color: #fff;
        }
      }
    }
  }

  &__main {
    padding: 15px 15px 72px 15px;
    overflow: hidden;
  }

  &__title {
    font-size: 16px;
    color: $headlines;
    margin-bottom: 10px;
  }

  &__description {
    font-size: 12px;
    color: $headlines;
    margin-bottom: 10px;
  }

  &__image {
    margin-bottom: 10px;
    display: block;
    width: 100%;
    pointer-events: none;
  }

  &__link {
    line-break: anywhere;
  }

  &__actions {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px 15px;
    border-top: 2px solid $gray--lighter;
  }

  &__edit,
  &__delete {
    padding: 0;
    border: none;
    outline: none;
    font: inherit;
    color: inherit;
    background: none;

    width: 33px;
    height: 33px;
    border-radius: 2px;
    background-color: $gray--lightest;
    color: $gray--light;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color .2s, color .2s;
  }

  &__delete {
    position: absolute;
    bottom: 32px;
    right: 37px;
    cursor: pointer;

    &:hover {
      color: #fff;
      background-color: #f00;
    }

    @media (max-width: $viewport-laptop--max) {
      bottom: 22px;
      right: 27px;
    }
  }
}
